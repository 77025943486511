<template>
  <div>
    <vx-card class="filter-card">
      <vs-row>
        <vs-col vs-w="12" vs-type="flex" vs-justify="flex-start" style="padding: 0px">
          <div class="eligibility_count">
            <p class="eligibility_text">My Leads</p>
            <p class="eligibility_text">{{ total_lead_count }}</p>
          </div>
        </vs-col>
      </vs-row>
      <vs-row>
        <vs-col vs-w="8.9" vs-offset="0.1" class="mt-2">
          <vs-row class="my-6">
            <vs-col vs-w="1" vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding">
              <p class="filter-heading">Filters</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" class="lead-tree-drop none_padding"
              id="team">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  Team
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect :disabled="disabled" :multiple="true" v-model="init_selected_team" placeholder="Select..."
                    :options="treeTeam" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.5"
              class="lead-tree-drop none_padding" id="citys">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  City
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedCity" :multiple="true" :options="treeDataCity" valueFormat="object"
                    :value-consists-of="'LEAF_PRIORITY'" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.5"
              class="lead-tree-drop none_padding" id="level_drop">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  Levels
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedLevels" :multiple="true" :options="treeDataLevels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <!-- <vs-col
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="2"
              s-offset="0.1"
              class="lead-tree-drop none_padding"
              id="macc_level_drop"
            >
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a
                  class="a-icon a-iconns ele-inside-text alignment py-1"
                  href.prevent
                  @click="showTreeBox"
                >
                  USP Levels
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect
                    v-model="initSelectedLevels"
                    :multiple="true"
                    :options="treeDataLevels"
                  />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col> -->
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.5"
              class="lead-tree-drop none_padding" id="macc_level_drop">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  USP Levels
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="init_selected_iiml_levels" :multiple="true" :options="macc_levels" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-row>
          <vs-row class="my-5">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" vs-offset="1" class="lead-tree-drop"
              id="spoc-dropdown">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text" href.prevent @click="showTreeBox" style="text-align: left;">
                  SPOC
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="dropdown-login customDropDown">
                  <treeselect v-model="initSelectedSpocs" :multiple="true" :options="SpoctreeData" valueFormat="object"
                    :value-consists-of="'LEAF_PRIORITY'" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" vs-offset="0.4" class="lead-tree-drop"
              id="course">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text" href.prevent @click="showTreeBox">
                  Course
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown">
                  <treeselect v-model="initSelectedCourses" :multiple="true" :options="treeDataCourses" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2.0" vs-offset="0.6"
              class="lead-tree-drop none_padding" id="focus">
              <vs-dropdown vs-custom-content vs-trigger-click>
                <a class="a-icon a-iconns ele-inside-text alignment py-1" href.prevent @click="showTreeBox">
                  Focus
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="customDropDown" style="z-index: 60000">
                  <treeselect v-model="initSelectedFocus" :options="treeDatafocus" />
                </vs-dropdown-menu>
              </vs-dropdown>
            </vs-col>
          </vs-row>

          <vs-row>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-w="1.1">
              <p class="filter-heading">Date Filter</p>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" class="none_padding mt-6" vs-align="center" vs-w="2.8">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Last Call</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false" :ranges="ranges"
                  :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false" :autoApply="false"
                  v-model="lastCallDateRange" class="lead">
                </date-range-picker>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" class="none_padding mt-6" vs-align="center" vs-offset="0.2"
              vs-w="2.8">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Next Call</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                  :ranges="next_call_date_ranges" :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false"
                  :autoApply="false" v-model="nextCallDateRange" class="lead">
                </date-range-picker>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" class="none_padding mt-6" vs-offset="0.2"
              vs-w="2.8">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Enrollment month & year</label>
                <datepicker placeholder="MM/YY" :minimumView="'month'" :maximumView="'month'" class="my_date_picker"
                  :format="customFormatter" v-model="dates"></datepicker>
              </div>
            </vs-col>
          </vs-row>
          <vs-row>
            <!-- <vs-col
              vs-type="flex"
              vs-justify="flex-start"
              vs-align="center"
              class="none_padding mt-6"
              vs-w="1.1"
            >
              <p class="filter-heading">Date Filter</p>
            </vs-col> -->

            <vs-col vs-type="flex" vs-justify="flex-start" class="none_padding mt-6" vs-align="center" vs-offset="1.1"
              vs-w="2.8">
              <div style="display: inline-grid; width: 100%; margin-top: -13px">
                <label class="date_filter_heading">Enrollment Date Range</label>
                <date-range-picker ref="picker" :opens="'right'" :locale-data="{
                  firstDay: 1,
                  format: 'DD-MMM-YYYY',
                }" :minDate="null" :maxDate="null" :singleDatePicker="false" :timePicker="false"
                  :ranges="next_call_date_ranges" :timePicker24Hour="true" :showWeekNumbers="false" :showDropdowns="false"
                  :autoApply="false" v-model="entrollmentDateRange" class="lead">
                </date-range-picker>
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="0.7" style="margin-top: 15px;">
              <div>
                <vs-button color="#0044ba" icon="clear" @click="clearDateFilter" size="small"></vs-button>
              </div>
            </vs-col>
          </vs-row>
          <vs-row class="mt-6 mb-2">
            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" class="none_padding" vs-w="11">
              <div class="lead_search_criteria mt-3">
                <h6 class="Criteria_heading ml-2 mt-2">Search Criteria:</h6>
                <vs-row vs-w="12" class="mt-2">
                  <vs-col vs-type="flex" vs-justify="flex-start" vs-align="flex-start" vs-offset="0.1" vs-w="10.7">
                    <div style="display: inline-block; width: 100%">
                      <div v-if="loggedin_team == 'Admin'">
                        <span v-for="chip in selected_teams" :key="chip">
                          <vs-chip @click="removechip(chip, 'team')" class="criteria_chip" closable color="#caecc5">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                      </div>
                      <div v-else>
                        <span v-for="chip in selected_teams" :key="chip">
                          <vs-chip class="criteria_chip" color="#caecc5">
                            <b>{{ chip }}</b>
                          </vs-chip>
                        </span>
                      </div>
                      <span v-for="chip in selectedCities" :key="chip.id">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip.id, 'city')" class="criteria_chip"
                          closable color="#eae5fa">
                          <b>{{ chip.label }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selected_levels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'level')" class="criteria_chip"
                          closable color="#ffdced">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selected_iiml_levels" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'iiml_level')" class="criteria_chip"
                          closable color="#ffdced">
                          <b v-if="chip == 'blank'">No U-Level</b>
                          <b v-else>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selected_spocs" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'spoc')" class="criteria_chip"
                          closable color="rgba(188, 235, 250, 0.69)">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <span v-for="chip in selected_courses" :key="chip">
                        <vs-chip v-if="chip !== 'divider'" @click="removechip(chip, 'course')" class="criteria_chip"
                          closable color="rgba(255, 194, 194, 0.69)">
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span>
                      <!-- <span v-for="chip in selected_focuses" :key="chip">
                        <vs-chip
                          v-if="chip !== 'divider'"
                          @click="removechip(chip, 'focus')"
                          class="criteria_chip"
                          closable
                          color="#ffcfb4"
                        >
                          <b>{{ chip }}</b>
                        </vs-chip>
                      </span> -->
                      <span>
                        <vs-chip v-if="initSelectedFocus !== null && initSelectedFocus !== undefined"
                          @click="removechip(initSelectedFocus, 'focus')" class="criteria_chip" closable color="#ffcfb4">
                          <b>{{ initSelectedFocus }}</b>
                        </vs-chip>
                      </span>
                    </div>
                  </vs-col>
                  <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-start" vs-w="1.2">
                    <vs-button color="#0044ba" icon="search" @click="analyzePagination()" size="small"></vs-button>
                    &nbsp;
                    <vs-button color="#0044ba" icon="refresh" @click="refreshBdeLeadsData()" size="small"></vs-button>
                  </vs-col>
                </vs-row>
              </div>
            </vs-col>
          </vs-row>
        </vs-col>
        <vs-col vs-w="3" vs-type="flex" class="no-padd" style="height: fit-content;">
          <vx-card class="lead-options_filter">
            <p class="lead-option-side mb-2">Options</p>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2 no-padding">
                <!-- <v-select v-if="spoc_logged" :options="SpocCityPrefilled" v-model="SpocPrefilled" :clearable="false"
                  class="lead-identity-input-filter" style="margin-right: 1%" /> -->
                <vs-input placeholder="Candidate ID" v-model="identity_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  @click="analyzePagination()" icon="search"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Candidate Name" v-model="can_name_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Contact Number" v-model="mobile_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Email Address" v-model="email_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Experience" v-model="experience_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-4">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Designation" v-model="designation_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="no-padding">
                <vs-input placeholder="Company" v-model="comapny_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="mb-1 no-padding">
                <vs-input placeholder="Comments" v-model="comments_search" class="lead-input-filter" />
                <vs-button style="position: absolute; right: 9%; color: #0044ba" color="#FFFFFF" type="gradient"
                  icon="search" @click="analyzePagination()"></vs-button>
              </vs-col>
            </vs-row>
            <vs-row class="mb-3">
              <vs-col class="no-padd" vs-type="flex" vs-justify="flex-start" vs-align="center">
                <vs-checkbox style="font-size: 14px;" v-model="ca_filter">CA Filter</vs-checkbox>
              </vs-col>
              <!-- <vs-col
                  vs-justify="flex-start"
                  vs-align="flex-end"
                  vs-w="2.5"
                >
                  <vs-button
                    color="#0044BA"
                    size="small"
                    icon="search"
                    @click="analyzePagination()"
                  ></vs-button>
              </vs-col> -->
            </vs-row>
            <vs-row class="mb-3">
              <vs-col class="no-padd" vs-justify="flex-start" vs-align="center">
                <vs-checkbox v-model="placement_assistance">Placement Assistance</vs-checkbox>
              </vs-col>
              <!-- <vs-col
                vs-justify="flex-start"
                vs-align="flex-end"
                vs-w="2.5"
              >
                <vs-button
                  size="small"
                  color="#0044BA"
                  icon="search"
                  @click="analyzePagination()"
                ></vs-button>
              </vs-col> -->
            </vs-row>
            <vs-row class="mb-3">
              <vs-col vs-type="flex" vs-justify="center" class="mb-2">
                <vs-button color="#0044BA" icon="search" size="small" @click="analyzePagination()">Search</vs-button>
              </vs-col>
            </vs-row>
          </vx-card>
        </vs-col>
      </vs-row>
    </vx-card>
    <div class="mt-3">
      <!-- <div class="mt-3" v-show="table_data_showing">  -->

      <leads-tabulator :all_leads="all_leads"></leads-tabulator>
      <div v-if="all_leads.length == 0" style="color: grey; margin: 5px">
        <center>NO DATA AVAILABLE</center>
      </div>
    </div>
    <vs-row style="margin-top: 3%">
      <vs-col vs-type="flex" vs-justify="flex-end" vs-align="flex-end" vs-w="12">
        <vs-pagination :total="tablelinks" v-model="currentTablePage"></vs-pagination>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
// import _ from "lodash";
import moment from "moment";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import EventBus from "../components/eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
import LeadsTabulator from "./LeadsTabulator.vue"
export default {
  components: {
    Datepicker,
    VTree,
    VSelectTree,
    DateRangePicker,
    Treeselect,
    "v-select": vSelect,
    VuePerfectScrollbar,
    LeadsTabulator
  },
  data() {
    return {
      ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 10 Days': [moment().subtract(10, 'days'), moment().subtract(1, 'days')],
        'Last 15 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        'Last 60 Days': [moment().subtract(60, 'days'), moment().subtract(1, 'days')],
        'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
      },
      next_call_date_ranges: {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'This month': [moment().startOf('month'), moment().endOf('month')],
        'This year': [moment().startOf('year'), moment().endOf('year')],
        'Last week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
        'Last month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 10 Days': [moment().subtract(10, 'days'), moment().subtract(1, 'days')],
        'Last 15 Days': [moment().subtract(15, 'days'), moment().subtract(1, 'days')],
        'Last 30 Days': [moment().subtract(30, 'days'), moment().subtract(1, 'days')],
        'Last 60 Days': [moment().subtract(60, 'days'), moment().subtract(1, 'days')],
        'Last 90 Days': [moment().subtract(90, 'days'), moment().subtract(1, 'days')],
      },
      dates: null,
      spoc_logged: false,
      SpocCityPrefilled: ["A", "B", "C", "D", "E", "H", "K", "M", "P", "G", "I"],
      SpocPrefilled: "",
      ca_filter: false,
      placement_assistance: false,
      logged_in_user_name: "",
      logged_in_user_id: "",
      disabled: false,
      total_lead_count: 0,
      // tablelinks: 0,
      raw_bde: [],
      identity_search: "",
      vaidentity_searchlue1: "",
      mobile_search: "",
      email_search: "",
      designation_search: "",
      comapny_search: "",
      comments_search: "",
      can_name_search: "",
      experience_search: "",
      selected_courses: [],
      selected_spocs: [],
      selected_levels: [],
      selected_iiml_levels: [],
      all_leads: [],
      tablelinks: 0,
      table_data_showing: false,
      currentTablePage: 1,
      lastCallDateRange: {
        startDate: null,
        endDate: null,
      },
      nextCallDateRange: {
        startDate: null,
        endDate: null,
      },
      entrollmentDateRange: {
        startDate: null,
        endDate: null,
      },
      selectedCities: [],
      searchCriteria: [],
      initSelectedCourses: [],
      initSelectedFocus: null,
      treeDatafocus: [
        {
          id: "delays",
          label: "Delays",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "waiting",
          label: "Waiting",
        },
        {
          id: "untapped",
          label: "Untapped",
        },
      ],
      treeDataCourses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            {
              id: "CPA-AA",
              label: "CPA-AA",
            },
            {
              id: "USP",
              label: "USP",
            },
            {
              id: "AICPA",
              label: "AICPA",
            },
            // {
            //   id: "CISA",
            //   label: "CISA",
            // },
            // {
            //   id: "EA",
            //   label: "EA",
            // },
            // {
            //   id: "CIA",
            //   label: "CIA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "CFA-L1",
              label: "CFA-L1",
            },
            {
              id: "CFA-L2",
              label: "CFA-L2",
            },
            {
              id: "CFA-L3",
              label: "CFA-L3",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "FRM-P1",
              label: "FRM-P1",
            },
            {
              id: "FRM-P2",
              label: "FRM-P2",
            },
            // {
            //   id: "IIMI-BA",
            //   label: "IIMI-BA",
            // },
            // {
            //   id: "IIMI-AA",
            //   label: "IIMI-AA",
            // },
            // {
            //   id: "XLRI-HR",
            //   label: "XLRI-HR",
            // },
            // {
            //   id: "XLRI-SH",
            //   label: "XLRI-SH",
            // },
            // {
            //   id: "RPO",
            //   label: "RPO",
            // },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            // {
            //   id: "USP",
            //   label: "USP",
            // },
            {
              id: "IIML-SF",
              label: "IIML-SF",
            },
          ],
        },
      ],
      macc_levels: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "blank", label: "No U Level" },
            { id: "U0--", label: "U0--" },
            { id: "U0", label: "U0" },
            { id: "U1", label: "U1" },
            { id: "U1+", label: "U1+" },
            { id: "U2", label: "U2" },
            { id: "U3-", label: "U3-" },
            { id: "U3", label: "U3" },
            { id: "U3+", label: "U3+" },
            { id: "U3++", label: "U3++"},
            { id: "U4", label: "U4" },
            { id: "U5", label: "U5" },
            { id: "U6", label: "U6" },
            { id: "U7-", label: "U7-" },
            { id: "U7", label: "U7" },
            { id: "U7R", label: "U7R" },
            { id: "U7+", label: "U7+" },
            { id: "U8", label: "U8" },
            { id: "U8+", label: "U8+" },
            { id: "U9-", label: "U9-" },
            { id: "U9", label: "U9" },
            { id: "U9+", label: "U9+" },
            { id: "U10-", label: "U10-" },
            { id: "U10", label: "U10" },
            { id: "U11", label: "U11" },
            { id: "U11+", label: "U11+" },
            { id: "U12", label: "U12" },
          ]
        }
      ],
      initSelectedSpocs: [],
      treeDataSrSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataExEdSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataCmSpoc: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      SpoctreeData: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      initSelectedLevels: [],
      init_selected_iiml_levels: [],
      initSelectedCity: [],
      selected_teams: [],
      init_selected_team: [],
      // treeDataLevels: [],
      // cities_list: [],
      cities: [],
      treeTeam: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "SR", label: "SR" },
            { id: "CM", label: "CM" },
            { id: "USP", label: "USP" },
          ]
        }
      ],
      treeDataCity: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "ENROLLED",
              label: "ENROLLED",
              children: [
                {
                    id: "M7",
                    label: "M7",
                },
                {
                    id: "M7-",
                    label: "M7-",
                },
                {
                    id: "M7+",
                    label: "M7+",
                },
                {
                    id: "M7#",
                    label: "M7#",
                },
                {
                    id: "M7-JJ",
                    label: "M7-JJ",
                },
                {
                    id: "M7X",
                    label: "M7X",
                },
                {
                    id: "M7D1",
                    label: "M7D1",
                },
                {
                    id: "M7D2",
                    label: "M7D2",
                },
                {
                    id: "M8#1",
                    label: "M8#1"
                },
                {
                    id: "M8#1-",
                    label: "M8#1-"
                },
                {
                    id: "M8#1+",
                    label: "M8#1+"
                },
                {
                    id: "M8#2",
                    label: "M8#2"
                },
                {
                    id: "M8",
                    label: "M8"
                },
                {
                    id: "M8-",
                    label: "M8-",
                },
                {
                    id: "M8+",
                    label: "M8+"
                },
                {
                    id: "M8+1",
                    label: "M8+1"
                },
                {
                    id: "M8+2",
                    label: "M8+2"
                },
                {
                    id: "M8+3",
                    label: "M8+3"
                },
                {
                    id: "M8+4",
                    label: "M8+4"
                },
                {
                    id: "M8+5",
                    label: "M8+5"
                },
                {
                    id: "M8+6",
                    label: "M8+6"
                },
                {
                    id: "M9",
                    label: "M9",
                },
                {
                    id: "M9+1",
                    label: "M9+1",
                },
                {
                    id: "M9+2",
                    label: "M9+2",
                },
                {
                    id: "M9+3",
                    label: "M9+3",
                },
                {
                    id: "M9-",
                    label: "M9-",
                },
                {
                    id: "M10",
                    label: "M10",
                },
                {
                    id: "M10#",
                    label: "M10#",
                },
                {
                    id: "M11",
                    label: "M11",
                },
                {
                    id: "M11#",
                    label: "M11#",
                },
                {
                    id: "M12",
                    label: "M12",
                },
                {
                    id: "M7-IR",
                    label: "M7-IR",
                },
                {
                    id: "M7-INT",
                    label: "M7-INT",
                }
              ]
            },
            {
              id: "Dnd",
              label: "Dnd",
              children: [
                {
                  id: "N/A",
                  label: "N/A",
                },
                {
                  id: "DND",
                  label: "DND",
                },
              ]
            }
          ],
        },
      ],
      // sr_levels: [
      //   {
      //     id: "All",
      //     label: "All",
      //     // children: [
      //     //   {
      //     //     id: "ENROLLED",
      //     //     label: "ENROLLED",
      //         children: [
      //           {
      //             id: "M7",
      //             label: "M7",
      //           },
      //           {
      //             id: "M7-",
      //             label: "M7-",
      //           },
      //           {
      //             id: "M7+",
      //             label: "M7+",
      //           },
      //           {
      //             id: "M7#",
      //             label: "M7#",
      //           },
      //           {
      //             id: "M7-JJ",
      //             label: "M7-JJ",
      //           },
      //           {
      //             id: "M7X",
      //             label: "M7X",
      //           },
      //           {
      //             id: "M7D1",
      //             label: "M7D1",
      //           },
      //           {
      //             id: "M7D2",
      //             label: "M7D2",
      //           },
      //           {
      //             id: "M8",
      //             label: "M8",
      //           },
      //           {
      //             id: "M8+",
      //             label: "M8+",
      //           },
      //           {
      //             id: "M8-",
      //             label: "M8-",
      //           },
      //           {
      //             id: "M9",
      //             label: "M9",
      //           },
      //           {
      //             id: "M9-",
      //             label: "M9-",
      //           },
      //           {
      //             id: "M10",
      //             label: "M10",
      //           },
      //         // ],
      //       // },
      //     ],
      //   },
      // ],
      loggedin_team: "",
      gm_spoc_ids: [],
      logged_in_user: [{ team: "", city: "", cc: "", user_tag: "", full_name: "" }],
      admin_access_spocs : [
      "kabir.chawla@mileseducation.com",
      "ghafir.samad@mileseducation.com",
      "sharon.j@mileseducation.com",
      "athira.m@mileseducation.com",
      "nidhin.ram@mileseducation.com",
      "ghouse.ahmed@mileseducation.com",
      "punith.rao@mileseducation.com",
      "satyam.bhivgade@mileseducation.com",
      "krishna.kanth@mileseducation.com",
      "satram.chaitanya@mileseducation.com",
      "johnadhoc@example.com"
    ],
    spoc_email_id: '',
    };
  },
  mounted() {
    this.logged_in_user_id = localStorage.getItem("user_id");
    this.init_selected_team = [];
    this.loggedin_team = localStorage.getItem('team');
    let sub_team = localStorage.getItem('sub_team');

    this.spoc_email_id = localStorage.getItem("email_id");
    console.log("Hello as admin",this.spoc_email_id, this.admin_access_spocs.includes(this.spoc_email_id));
    if (this.logged_in_user_id == 2613 || this.logged_in_user_id == 2474 || localStorage.getItem("spoc_id") == 794 || this.admin_access_spocs.includes(this.spoc_email_id) || (this.loggedin_team === 'ExEd' && sub_team === 'MAcc') ) {
      console.log("Hello as admin");
      this.loggedin_team = 'Admin';
    }
    console.log("this.loggedin_team", this.loggedin_team);
    if (this.getTeam("GM") && this.getGmReportingSpocs()) {
      this.myprofile()
    } else if (this.getTeam("CM")) {
      this.myprofile()
    }
    if (this.loggedin_team == 'Admin') {
      this.disabled = false;
    } else {
      this.disabled = true;
    }
    if (this.loggedin_team == 'Admin' || this.loggedin_team == 'GM') {
      this.spoc_logged = false;
    } else {
      this.spoc_logged = true;
    }
    if (this.loggedin_team === 'CM') {
      this.init_selected_team.push('CM');
    } else if (this.loggedin_team === 'SR') {
      this.init_selected_team.push('SR');
    } else if (this.loggedin_team === 'ExEd') {
      this.init_selected_team.push('USP');
    } else if (this.loggedin_team === 'GM' && sub_team === 'CM') {
      this.init_selected_team.push('CM');
    } else if (this.loggedin_team === 'GM' && sub_team != 'CM') {
      this.init_selected_team.push('SR');
    } else {
      this.init_selected_team.push('CM');
    }

  },
  created() {
    this.init_selected_team = [];
    let team = localStorage.getItem('team');
    if (team == 'CM' || team == 'SR') {
      this.init_selected_team.push(team)
    } else if (team == 'ExEd') {
      this.init_selected_team.push('USP')
    }
    if (this.getTeam("GM") && this.getGmReportingSpocs()) {
      this.myprofile()
    } else if (this.getTeam("CM") || this.getTeam('ExEd')) {
      this.myprofile()
    }
  },
  watch: {
    dates(val) {
      if (val != null) {
        this.entrollmentDateRange = {
          startDate: null,
          endDate: null,
        }
      }
    },
    entrollmentDateRange(val) {
      if (val.startDate != null && val.endDate != null) {
        this.dates = null;
      }
    },
    SpocPrefilled() {
      this.analyzePagination();
    },
    currentTablePage() {
      this.analyzePagination();
    },
    SpoctreeData(val) {
      console.log("SpoctreeData", val);
    },
    selected_levels(val) {
      console.log("selected_levels", val);
    },
    init_selected_team(val) {
      console.log("init_selected_team", val);
      this.initSelectedCity = [];
      this.initSelectedLevels = [];
      this.init_selected_iiml_levels = [];
      this.initSelectedSpocs = [];
      this.initSelectedCourses = [];
      this.selectedCities = [];
      this.selected_levels = [];
      this.selected_iiml_levels = [];
      this.selected_spocs = [];
      this.selected_courses = [];
      this.treeDataCity = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ];
      this.SpoctreeData = [];
      if (val == "CM") {
        // if (this.getTeam("GM") && this.getGmReportingSpocs()) {
        //   this.myprofile()
        // } else if (this.getTeam("CM")) {
        //   this.myprofile()
        // }
        this.myprofile()
        // this.getSpocs();
        // this.treeDataLevels = this.cm_levels;
      }
      if (val == "SR") {
        console.log("call1",val);
        this.myprofile()
        // this.getSRSpoc();
        // this.treeDataLevels = this.sr_levels;
      }
      if (val == "USP") {
        console.log("getExedSpoc 1");
        this.myprofile()
        // this.getExedSpoc()
        // this.treeDataLevels = this.macc_levels;
      }
      this.mergeAllSearch();
      EventBus.$emit("selected-team", this.selected_teams);
    },
    initSelectedCity(val) {
      console.log("selected city", val);
      if (val.length != 0) {
        this.mergeAllSearch();
      } else {
        this.selectedCities = []
        if (this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'GM' || this.logged_in_user.team == 'SR') {
          this.initSelectedCity = [{ id: 'All', label: 'All', children: this.treeDataCity[0].children }]
          this.selectedCities = [this.logged_in_user.city]
        }
      }
    },
    initSelectedLevels(val) {
      console.log("initSelectedLevels", val);
      this.mergeAllSearch();
    },
    init_selected_iiml_levels(val) {
      console.log("init_selected_iiml_levels", val);
      this.mergeAllSearch();
    },
    initSelectedSpocs(val) {
      if (val.length != 0) {
        this.mergeAllSearch();
      } else {
        this.selected_spocs = []
        if (this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'ExEd' || this.logged_in_user.team == 'SR') {
          this.initSelectedSpocs = [{ id: this.logged_in_user.id, label: this.logged_in_user.full_name }]
          console.log("spoc name",this.initSelectedSpocs);
          this.selected_spocs = [this.logged_in_user.full_name]
        } else if (this.logged_in_user.team == 'GM' && this.gm_spoc_ids.length != 0) {
          console.log("this.gm_spoc_ids11", this.gm_spoc_ids);
          let duplicates = []
          this.initSelectedSpocs = []
          this.gm_spoc_ids.forEach(element => {
            if (!duplicates.includes(element.id) && element.active) {
              this.initSelectedSpocs.push({ id: element.id, label: element.full_name })
              this.selected_spocs.push(element.full_name)
              duplicates.push(element.id)
            }
          })
        }
      }
      console.log("this.initSelectedSpocs 2", this.initSelectedSpocs);
    },
    initSelectedCourses(val) {
      console.log("initSelectedCourses", val);
      this.mergeAllSearch();
    },
    initSelectedFocus(val) {
      console.log("initSelectedFocus", val);
      this.mergeAllSearch();
    }
  },
  methods: {
    clearDateFilter() {
      this.lastCallDateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null;
      this.nextCallDateRange = {
        startDate: null,
        endDate: null,
      };
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      }
    },
    getGmReportingSpocs() {
      let gm_id = localStorage.getItem("spoc_id");
      let ids = [];
      let url = `${constants.SERVER_API}getSpocsOfGM?gm_id=${gm_id}`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getGmReportingSpocs", response);
          if (response.data.spocs.length == 0) {
            this.loggedin_team = 'Admin';
            return false
          } else {
            for (let i = 0; i < response.data.spocs.length; i++) {
              const element = response.data.spocs[i];
              ids.push(element.id);
            }
            this.gm_spoc_ids = response.data.spocs
            return true
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    myprofile() {
      let url = `${constants.SERVER_API}getUserProfile`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("My profile**", response.data.data);
          let data = response.data.data;
          if(data.id != 794 && !this.admin_access_spocs.includes(this.spoc_email_id) && data.team != 'ExEd' && data.sub_team != 'MAcc'){
          this.logged_in_user.id = data.id
          this.logged_in_user.team = data.team
          console.log("call6",this.logged_in_user.team);
          this.logged_in_user.city = data.city
          this.logged_in_user.cc = data.city_classification
          this.logged_in_user.user_tag = data.user_tag
          this.logged_in_user.full_name = data.full_name
          }
          // this.getAllActiveSpocsForDashboard();
          if(this.logged_in_user.team == 'MAcc' || this.init_selected_team.includes('USP'))
          {
            this.getExedSpoc()
          }
          if(this.logged_in_user.team == 'SR' || this.init_selected_team.includes('SR')) {
             this.getSRSpoc();
          }
          if(this.logged_in_user.team == 'CM' || this.init_selected_team.includes('CM')) {
             this.getSpocs();
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
      // if (this.logged_in_user.team == 'ExEd') {

      // }else{
      //   this.getSpocs()
      // }

    },
    showTreeBox() {
      return this.treeDataCourses;
    },
    datatoTimestamp(stringyDate) {
      var dateyDate = new Date(this.timetoTimestamp(stringyDate));
      var ms = dateyDate.valueOf();
      return ms;
    },
    customFormatter(date) {
      return moment(date).format("MMM-YYYY");
    },
    refreshBdeLeadsData() {
      this.identity_search = "";
      this.can_name_search = "";
      this.entrollmentDateRange = {
        startDate: null,
        endDate: null,
      };
      this.dates = null,
        this.email_search = "";
      this.experience_search = "";
      this.designation_search = "";
      this.comapny_search = "";
      this.comments_search = "";
      this.ca_filter = false;
      this.placement_assistance = false;
      this.initSelectedLevels = [];
      this.init_selected_iiml_levels = [];
      this.initSelectedCourses = [];
      this.initSelectedFocus = null;
      if (this.logged_in_user.team != 'CM' || this.logged_in_user.team != 'GM') {
        this.init_selected_team = [];
        this.initSelectedCity = [];
        this.initSelectedSpocs = [];
        this.selected_spocs = [];
        this.selectedCities = [];
      }
      this.selected_levels = [];
      this.selected_courses = [];
      if (this.loggedin_team == 'Admin') {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
      if (this.loggedin_team === 'CM') {
        this.init_selected_team.push('CM');
      } else if (this.loggedin_team === 'SR') {
        this.init_selected_team.push('SR');
      } else if (this.loggedin_team === 'ExEd') {
        this.init_selected_team.push('USP');
      } else if (this.loggedin_team === 'GM' && localStorage.getItem('sub_team') === 'CM') {
        this.init_selected_team.push('CM');
      } else if (this.loggedin_team === 'GM' && localStorage.getItem('sub_team') != 'CM') {
        this.init_selected_team.push('SR');
      } else {
        this.init_selected_team.push('CM');
      }
      // if (val == "CM") {
      //   this.getSpocs();
      //   // this.treeDataLevels = this.cm_levels;
      // }
      // if (val == "SR") {
      //   this.getSRSpoc();
      //   // this.treeDataLevels = this.sr_levels;
      // }
      // if (val == "USP") {
      //   console.log("getExedSpoc 1");
      //   this.getExedSpoc();
      //   // this.treeDataLevels = this.macc_levels;
      // }
    },
    analyzePagination() {
      this.$vs.loading();
      let spoc_ids = [];
      // let levels = [];
      let courses = [];
      console.log("analyzePagination initSelectedSpocs", this.initSelectedSpocs);
      let year = "";
      let month = "";
      if (this.dates != null) {
        year = moment(this.dates).format("YYYY");
        month = moment(this.dates).format("MM");
      }
      var enrollment_data_from_date = "";
      var enrollment_data_to_date = "";
      if (this.entrollmentDateRange.startDate != null && this.entrollmentDateRange.endDate != null) {
        enrollment_data_from_date = this.datatoTimestamp(this.entrollmentDateRange.startDate);
        enrollment_data_to_date = this.datatoTimestamp(this.entrollmentDateRange.endDate);
      }
      this.initSelectedSpocs.forEach(element => {
        console.log("initSelectedSpocs", element);
        if (element === "All") {
          this.SpoctreeData[0].children.forEach((child) => {
            spoc_ids.push(child.id);
          });
        } else {
          console.log("spoc_ids jdhfjdgh", spoc_ids, spoc_ids.includes(element.id));
          // if(!spoc_ids.includes(element.id)){
          spoc_ids.push(element.id);
          // }
        }
      });

      this.initSelectedCity.forEach(city => {
        let string = city.id.split('_');
        // city_classification.push(string[1]);
        // cities.push(string[0]);
        this.SpoctreeData[0].children.forEach(spoc => {
          spoc.children.forEach(element => {
            console.log("check data", string[0], string[1], spoc, element);
            if (string[0] == element.city && string[1] == element.city_classification) {
              spoc_ids.push(element.id);
            }
          })

        });

      });
      if (this.initSelectedCourses.includes('All')) {
        this.treeDataCourses[0].children.forEach(element => {
          courses.push(element.id);
        });
      } else {
        this.initSelectedCourses.forEach(element => {
          courses.push(element);
        });
      }
      let modified_selected_teams = JSON.parse(JSON.stringify(this.selected_teams));
      if (modified_selected_teams.includes('USP')) {
        modified_selected_teams.splice(modified_selected_teams.indexOf('USP'), 1);
        modified_selected_teams.push('ExEd');
      }

      let obj = {
        team: modified_selected_teams.join(),
        courses: courses.join(),
        levels: this.selected_levels.join(),
        enrollment_data_from_date: enrollment_data_from_date,
        enrollment_data_to_date: enrollment_data_to_date,
        my_focus: this.initSelectedFocus,
        spoc_ids: spoc_ids.join(),
        iiml_levels: this.selected_iiml_levels.join(),
        name: this.can_name_search,
        identity: this.identity_search,
        company: this.comapny_search,
        mobile: this.mobile_search,
        email: this.email_search,
        experience: this.experience_search,
        designation: this.designation_search,
        ca_filter: this.ca_filter,
        placement_assistance: this.placement_assistance,
        enrollment_month: month,
        enrollment_year: year,
        // visitors: visitors,
        // net_enquiry: net_enquiry,
        // eligibility: eligibility,
        // reference: reference,
        // loan: loan,
        comments: this.comments_search,
        // international_city: this.city_search,
        // country: this.country_search,
        // corporate: corporate,
        // university: university,
        from_date: "",
        to_date: "",
        next_call_from_date: "",
        next_call_to_date: "",
      };

      // if (this.loggedin_team !== 'Admin' && this.loggedin_team !== 'GM' && this.loggedin_team !== 'SR' && this.identity_search !== '') {
      //   obj.identity = `${this.SpocPrefilled}-${this.identity_search}`;
      // }
      if (
        this.lastCallDateRange.startDate !== null &&
        this.lastCallDateRange.endDate !== null
      ) {
        obj.from_date = this.datatoTimestamp(this.lastCallDateRange.startDate);
        obj.to_date = this.datatoTimestamp(this.lastCallDateRange.endDate);
      }
      if (
        this.nextCallDateRange.startDate !== null &&
        this.nextCallDateRange.endDate !== null
      ) {
        obj.next_call_from_date = this.datatoTimestamp(this.nextCallDateRange.startDate);
        obj.next_call_to_date = this.datatoTimestamp(this.nextCallDateRange.endDate);
      }
      this.all_leads = [];
      let url = `${constants.SERVER_API}getSearchedLeads?page=${this.currentTablePage}`;
      axios
        .post(url, obj, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          // this.$vs.loading.close();
          // console.log("laedas data", response);
          // this.countsleads = response.data.total;
          // response.data.data.forEach((lead) => {
          //   lead.engagements = [];
          //   lead.mobile = [];
          //   lead.emails = [];
          // });
          // this.currentTablePage = response.data.current_page;
          // this.tablelinks = response.data.last_page;
          this.all_leads = response.data.data;
          for (let i = 0; i < this.all_leads.length; i++) {
            const element = this.all_leads[i];
            if (element.mhp_date != null) {
              // console.log("dfgrtr",element.mhp_date);
              element.mhp_date = this.convertTimestampToCustomFormat(element.mhp_date);
            }
            if (element.last_call != null) {
              element.last_call = this.convertTimestampToCustomFormat(element.last_call);
            }
            if (element.next_call != null) {
              element.next_call = this.convertTimestampToCustomFormat(element.next_call);
            }
            if (element.sr_last_call != null) {
              element.sr_last_call = this.convertTimestampToCustomFormat(element.sr_last_call);
            }
            if (element.sr_next_call != null) {
              element.sr_next_call = this.convertTimestampToCustomFormat(element.sr_next_call);
            }
            if (element.macc_last_call != null) {
              element.macc_last_call = this.convertTimestampToCustomFormat(element.macc_last_call);
            }
            if (element.macc_next_call != null) {
              element.macc_next_call = this.convertTimestampToCustomFormat(element.macc_next_call);
            }
            console.log("element.enrollment_date", element.enrollment_date);
            if (element.enrollment_date != '' && element.enrollment_date != null) {
              element.enrollment_date = this.convertTimestampToCustomFormat(element.enrollment_date);
            }
            Object.assign(element, { info: "https://icon-library.com/images/info-icon-png-transparent/info-icon-png-transparent-25.jpg" });
            Object.assign(element, { erollment_form: "https://cdn-icons-png.flaticon.com/512/61/61183.png" });
            Object.assign(element, { iiml_erollment_form: "https://cdn-icons-png.flaticon.com/512/61/61183.png" });
          }
          this.currentTablePage = response.data.current_page;
          this.tablelinks = response.data.last_page;
          console.log("this.all_leads", response.data);
          this.total_lead_count = response.data.total;
          console.log("getSearchedMwbLeads response", response);
          this.$vs.loading.close();
          // this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.handleError(error);
        });
    },
    convertTimestampToCustomFormat(date) {
      let new_date = moment.unix(date).format("DD-MMM-YYYY");
      return new_date;
    },
    removechip(chip, name) {
      console.log("chip", chip, name);

      if (name == "team") {
        this.init_selected_team.splice(this.init_selected_team.indexOf(chip), 1);
      }
      if (name == "city" && this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team != 'SR') {
        let index = this.initSelectedCity.findIndex(x => x.id === chip);
        this.initSelectedCity.splice(index, 1);
        this.selectedCities.splice(this.selectedCities.indexOf(chip), 1);
        // this.initSelectedCity.splice(this.initSelectedCity.indexOf(chip), 1);
      }
      if (name == "level") {
        // this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
        if (this.initSelectedLevels.indexOf("All") == 0) {
          this.initSelectedLevels = [];
          this.treeDataLevels[0].children.forEach((child) => {
            child.children.forEach((chi) => {
              if (chi.label !== chip) {
                this.initSelectedLevels.push(chi.label);
                this.selected_levels.push(chi.label);
              }
            });
          });
        } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
          this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          this.selected_levels.splice(this.initSelectedLevels.indexOf(chip), 1);
        } else {
          let childProperty = "";
          this.treeDataLevels[0].children.forEach((child) => {
            if ("children" in child) {
              child.children.forEach((chi) => {
                if (chi.label === chip) {
                  childProperty = child.label;
                }
              });
            }
          });
          this.initSelectedLevels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1
          );
          this.selected_levels.splice(
            this.initSelectedLevels.indexOf(childProperty),
            1);
          this.treeDataLevels[0].children.forEach((child) => {
            if (child.label === childProperty) {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label !== chip) {
                    this.initSelectedLevels.push(chi.label);
                    this.selected_levels.push(chi.label);
                  }
                });
              }
            }
          });
        }
      }
      if (name == 'iiml_level') {
        this.selected_iiml_levels = [];
        if (this.init_selected_iiml_levels.indexOf(chip) === -1) {
          this.init_selected_iiml_levels = [];
          this.macc_levels[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.init_selected_iiml_levels.push(child.label);
              this.selected_iiml_levels.push(child.label);
            }
          });
        } else {
          this.init_selected_iiml_levels.splice(this.init_selected_iiml_levels.indexOf(chip), 1);
          this.selected_iiml_levels.splice(
            this.selected_iiml_levels.indexOf(chip),
            1
          );
        }
      }
      let check_empty = false
      if (this.logged_in_user.team != 'CM' && this.logged_in_user.team != 'GM' && this.logged_in_user.team == 'ExEd') {
        check_empty = true
      } else {
        if (this.initSelectedSpocs.length >= 2) {
          check_empty = true
        }
      }
      if (name == "spoc" && check_empty) {
        let index = this.initSelectedSpocs.findIndex(x => x.label === chip);
        this.initSelectedSpocs.splice(index, 1);
        this.selected_spocs.splice(this.selected_spocs.indexOf(chip), 1);
      }
      if (name == "course") {
        this.selected_courses = [];
        // this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
        if (this.initSelectedCourses.indexOf(chip) === -1) {
          this.initSelectedCourses = [];
          this.treeDataCourses[0].children.forEach((child) => {
            if (child.label !== chip) {
              this.initSelectedCourses.push(child.label);
              this.selected_courses.push(child.label);
            }
          });
        } else {
          this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          this.selected_courses.splice(
            this.selected_courses.indexOf(chip),
            1
          );
        }
      }
      if (name == "focus") {
        this.initSelectedFocus = null;
      }
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.selected_teams = [];
      this.init_selected_team.forEach((sort) => {
        console.log("init_selected_teamwewe", sort);
        if (sort === "All") {
          console.log("init_selected_team 1");
          this.treeTeam[0].children.forEach((child) => {
            this.selected_teams.push(child.label);
          });
        } else {
          console.log("init_selected_team 2");
          this.selected_teams.push(sort);
        }
      });
      this.selectedCities = [];
      this.initSelectedCity.forEach((sort) => {
        console.log("initSelectedCity", sort);
        // if (sort.label === "All") {
        //   console.log("all city",sort);
        //   this.initSelectedCity[0].children.forEach((child) => {
        //     this.selectedCities.push(child);
        //   });
        // } else {
        //   console.log("not all city",sort.label);
        this.selectedCities.push(sort);
        // }
        // if(sort.id.includes('_')){
        //   this.selectedCities.push(sort);
        // let string = sort.id.split('_');
        // if(!this.selectedCities.includes(string[0])){
        //   this.selectedCities.push(string[0])
        // }
        // }
      });
      //initSelectedLevels
      this.selected_levels = [];
      console.log("initSelectedLevels array", this.initSelectedLevels);
      this.initSelectedLevels.forEach((sort) => {
        console.log("initSelectedLevels element", sort);
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else if (sort === "ENROLLED") {
          this.treeDataLevels[0].children[3].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        }
        else if (sort === "Dnd") {
          this.treeDataLevels[0].children[4].children.forEach((child) => {
            this.selected_levels.push(child.label);
          });
        } else {
          console.log("sort", sort);
          this.selected_levels.push(sort);
        }
        // } else {
        //   this.selected_levels.push(sort);
        // }
      });
      //init_selected_iiml_levels
      this.selected_iiml_levels = [];
      this.init_selected_iiml_levels.forEach((sort) => {
        if (sort === "All") {
          this.macc_levels[0].children.forEach((child) => {
            this.selected_iiml_levels.push(child.label);
          });
        } else {
          this.selected_iiml_levels.push(sort);
        }
      });
      //initSelectedSpocs
      this.selected_spocs = [];
      this.initSelectedSpocs.forEach((sort) => {
        if (sort.label === "All") {
          this.SpoctreeData[0].children.forEach((child) => {
            if (!this.selected_spocs.includes(child.label)) {
              this.selected_spocs.push(child.label);
            }
          });
        } else {
          if (!this.selected_spocs.includes(sort.label)) {
            this.selected_spocs.push(sort.label);
          }
        }
      });
      //this.selected_spocs = [];
      this.selected_courses = [];
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            // this.searchCriteria.push(child.label);
            this.selected_courses.push(child.label);
          });
        } else {
          // this.searchCriteria.push(sort);
          this.selected_courses.push(sort);
        }
      });
    },
    getExedSpoc() {
      console.log("getExedSpoc 2");
      let url = `${constants.SERVER_API}getExed`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          console.log("getCityWiseExEdSpocs", response.data.spocs);
          this.logged_in_user_name = response.data.logged_in_user_name;
          this.logged_in_user_id = response.data.logged_in_user_id;
          if (this.loggedin_team != 'Admin' && this.loggedin_team != 'GM') {
            // let obj = {
            //   id: this.logged_in_user_id,
            //   label: this.logged_in_user_name
            // }
            // this.initSelectedSpocs.push(obj);
            // let usercity = '';
            response.data.spocs.forEach((spoc) => {
              if (spoc.id === this.logged_in_user_id) {
                // usercity = spoc.city;
                this.spoc_city = spoc.city;
                let spoc_prefilled = spoc.city.charAt(0).toUpperCase();
                if (this.SpocCityPrefilled.includes(spoc_prefilled) && spoc.city != "IIML-FT") {
                  this.SpocPrefilled = spoc_prefilled;
                }
                else {
                  this.SpocPrefilled = "B";
                }
              }
            });
          }
          this.getCityClassificationSpocs(response.data.spocs);
          this.getCityWiseExEdSpocs(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSRSpoc() {
      let url = `${constants.SERVER_API}getSRSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.logged_in_user_name = response.data.logged_in_user_name;
          this.logged_in_user_id = response.data.logged_in_user_id;
          if (this.loggedin_team != 'Admin' && this.loggedin_team != 'GM') {
            response.data.spocs.forEach((spoc) => {
              if (spoc.id === this.logged_in_user_id) {
                this.spoc_city = spoc.city;
                let spoc_prefilled = spoc.city.charAt(0).toUpperCase();
                if (this.SpocCityPrefilled.includes(spoc_prefilled) && spoc.city != "IIML-FT") {
                  this.SpocPrefilled = spoc_prefilled;
                }
                else {
                  this.SpocPrefilled = "B";
                }
              }
            });
          }
          this.getCityClassificationSpocs(response.data.spocs);
          this.getCityWiseSrSpocs(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocs() {
      console.log("CM");
      let url = `${constants.SERVER_API}getSpocs`;
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          this.raw_bde = response.data.spocs;
          console.log("this.raw_bde", this.raw_bde);
          this.logged_in_user_name = response.data.logged_in_user_name;
          this.logged_in_user_id = response.data.logged_in_user_id;
          if (this.loggedin_team != 'Admin' && this.loggedin_team != 'GM') {
            response.data.spocs.forEach((spoc) => {
              if (spoc.id === this.logged_in_user_id) {
                this.spoc_city = spoc.city;
                let spoc_prefilled = spoc.city.charAt(0).toUpperCase();
                if (this.SpocCityPrefilled.includes(spoc_prefilled) && spoc.city != "IIML-FT") {
                  this.SpocPrefilled = spoc_prefilled;
                }
                else {
                  this.SpocPrefilled = "B";
                }
              }
            });
          }
          this.getCityClassificationSpocs(response.data.spocs);
          this.getCityWiseSpocs(response.data.spocs);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getCityWiseSpocs(spocs_data) {
      this.SpoctreeData = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
        this.treeDataCmSpoc = [
          {
            id: "All",
            label: "All",
            children: [],
          },
        ],
        // this.cities = [];
        this.cities = [...new Set(spocs_data.map((item) => item.city))];
      let cities_list = [...new Set(spocs_data.map((item) => item.city))];
      let duplicates2 = []
      let duplicates = []
      console.log("cities list order", cities_list);
      this.treeDataCmSpoc[0].children = [];
      for (let i = 0; i < cities_list.length; i++) {
        const element = cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
            children: [],
          };
          if (this.logged_in_user.team == 'CM') {
            if (this.logged_in_user.city == element) {
              this.treeDataCmSpoc[0].children.push(obj);
            }
          } else if (this.logged_in_user.team == 'GM') {
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.city == element && !duplicates.includes(element)) {
                this.treeDataCmSpoc[0].children.push(obj);
                duplicates.push(element)
              }
            });
          } else { this.treeDataCmSpoc[0].children.push(obj); }
        }
      }
      this.treeDataCmSpoc[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataCmSpoc[0].children
      );
      spocs_data.forEach((element) => {
        this.treeDataCmSpoc[0].children.forEach((city) => {
          if (element.city == city.label) {

            // var obj = {
            //   id: element.id, // x is in more than one city
            //   label: element.full_name,
            //   city: element.city,
            //   city_classification: element.city_classification
            // };

            // if (this.logged_in_user.team == 'CM' ) {
            //   if (this.logged_in_user.full_name == element.full_name) {
            //     city.children.push(obj);
            //     this.initSelectedSpocs = []
            //     this.initSelectedSpocs.push(obj);
            //     // this.initSelectedSpocs = this.SpoctreeData
            //     this.selected_spocs = [this.logged_in_user.full_name]
            //   }
            // } else if (this.logged_in_user.team == 'GM') {
            //   this.gm_spoc_ids.forEach(element1 => {
            //     if (element1.full_name == element.full_name && !duplicates2.includes(element.full_name)) {
            //       city.children.push(obj);
            //       duplicates2.push(element.full_name)
            //     }
            //   });
            // } else { city.children.push(obj); }
            if(this.logged_in_user_id == 132 ||this.logged_in_user_id == 937  || this.logged_in_user_id == 39){
               obj = {
                id: element.id,
                label: element.full_name,
                city: element.city,
                city_classification: element.city_classification
               };
               if (this.logged_in_user.team == 'CM' ) {
                if (this.logged_in_user.full_name == element.full_name) {
                  city.children.push(obj);
                  this.initSelectedSpocs = []
                  this.initSelectedSpocs.push(obj);
                  this.selected_spocs = [this.logged_in_user.full_name]
                }
               }
               else if (this.logged_in_user.team == 'GM') {
                this.gm_spoc_ids.forEach(element1 => {
                if (element1.full_name == element.full_name && !duplicates2.includes(element.full_name)) {
                  city.children.push(obj);
                  duplicates2.push(element.full_name)
                }
                });
                }
                else { city.children.push(obj); }
            }
           else{
            if(element.full_name.substring(0,1) != 'X'){
              obj = {
                id: element.id,
                label: element.full_name,
                city: element.city,
                city_classification: element.city_classification
              };
            if (this.logged_in_user.team == 'CM' ) {
              if (this.logged_in_user.full_name == element.full_name) {
                city.children.push(obj);
                this.initSelectedSpocs = []
                this.initSelectedSpocs.push(obj);
                // this.initSelectedSpocs = this.SpoctreeData
                this.selected_spocs = [this.logged_in_user.full_name]
              }
            }
            else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.full_name == element.full_name && !duplicates2.includes(element.full_name)) {
                  city.children.push(obj);
                  duplicates2.push(element.full_name)
                }
              });
            } else { city.children.push(obj); }
            }
           }

          }
          city = this.sortNestedArrayAlphabetically(city.children);
        });
      });
      this.SpoctreeData = this.treeDataCmSpoc;

      console.log("this.all_leads get city wise spoc3", spocs_data);
      this.analyzePagination()
    },
    getCityWiseSrSpocs(spocs_data) {
      this.SpoctreeData = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
        this.treeDataSrSpoc = [
          {
            id: "All",
            label: "All",
            children: [],
          },
        ],
        // this.cities = [];
        this.cities = [...new Set(spocs_data.map((item) => item.city))];
      let cities_list = [...new Set(spocs_data.map((item) => item.city))];
      console.log("cities list order", cities_list);
      let duplicates2 = []
      this.treeDataSrSpoc[0].children = [];
      for (let i = 0; i < cities_list.length; i++) {
        const element = cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
            children: [],
          };
          if (this.logged_in_user.team == 'SR') {
            if (this.logged_in_user.city == element) {
              this.treeDataSrSpoc[0].children.push(obj);
            }
          } else if (this.logged_in_user.team == 'GM') {
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.city == element && !duplicates2.includes(element)) {
                this.treeDataSrSpoc[0].children.push(obj);
                duplicates2.push(element)
              }
            });
          } else { this.treeDataSrSpoc[0].children.push(obj); }
        }
      }
      this.treeDataSrSpoc[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataSrSpoc[0].children
      );
      spocs_data.forEach((element) => {
        this.treeDataSrSpoc[0].children.forEach((city) => {
          if (element.city == city.label) {

            var obj = {
              id: element.id, // x is in more than one city
              label: element.full_name,
              city: element.city,
              city_classification: element.city_classification
            };
            if (this.logged_in_user.team == 'SR') {
              if (this.logged_in_user.full_name == element.full_name) {
                city.children.push(obj);
                this.initSelectedSpocs = []
                this.initSelectedSpocs.push(obj);
                // this.initSelectedSpocs = this.SpoctreeData
                this.selected_spocs = [this.logged_in_user.full_name]
              }
            } else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.full_name == element.full_name && !duplicates2.includes(element.full_name)) {
                  city.children.push(obj);
                  duplicates2.push(element.full_name)
                }
              });
            } else { city.children.push(obj); }
            // }
            // }
          }
          city = this.sortNestedArrayAlphabetically(city.children)
        });
      });
      this.SpoctreeData = this.treeDataSrSpoc;
      this.analyzePagination();
    },
    getCityWiseExEdSpocs(spocs_data) {
      this.SpoctreeData = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
        this.treeDataExEdSpoc = [
          {
            id: "All",
            label: "All",
            children: [],
          },
        ],
        // this.cities = [];
        this.cities = [...new Set(spocs_data.map((item) => item.city))];
      let cities_list = [...new Set(spocs_data.map((item) => item.city))];
      console.log("cities list order", cities_list);
      let duplicates2 = []
      this.treeDataExEdSpoc[0].children = [];
      for (let i = 0; i < cities_list.length; i++) {
        const element = cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
            children: [],
          };
          if (this.logged_in_user.team == 'ExEd') {
            if (this.logged_in_user.city == element) {
              this.treeDataExEdSpoc[0].children.push(obj);
            }
          } else if (this.logged_in_user.team == 'GM') {
            this.gm_spoc_ids.forEach(element1 => {
              if (element1.city == element && !duplicates2.includes(element)) {
                this.treeDataExEdSpoc[0].children.push(obj);
                duplicates2.push(element)
              }
            });
          } else { this.treeDataExEdSpoc[0].children.push(obj); }
        }
      }
      this.treeDataExEdSpoc[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataExEdSpoc[0].children
      );
      spocs_data.forEach((element) => {
        this.treeDataExEdSpoc[0].children.forEach((city) => {
          if (element.city == city.label) {

            var obj = {
              id: element.id, // x is in more than one city
              label: element.full_name,
              city: element.city,
              city_classification: element.city_classification
            };
            if (this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'ExEd') {
              if (this.logged_in_user.full_name == element.full_name) {
                city.children.push(obj);
                this.initSelectedSpocs = []
                this.initSelectedSpocs.push(obj);
                // this.initSelectedSpocs = this.SpoctreeData
                this.selected_spocs = [this.logged_in_user.full_name]
              }
            } else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.full_name == element.full_name && !duplicates2.includes(element.full_name)) {
                  city.children.push(obj);
                  duplicates2.push(element.full_name)
                }
              });
            } else { city.children.push(obj); }

          }
          city = this.sortNestedArrayAlphabetically(city.children)
        });
      });
      this.SpoctreeData = this.treeDataExEdSpoc;
      console.log("spocs_data 21324", spocs_data);
    },
    getCityClassificationSrSpocs(spocs_data) {
      this.treeDataCity = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ]
      let cities_list = [...new Set(spocs_data.map((item) => item.city))];
      console.log("cities list order", cities_list);
      this.treeDataCity[0].children = [];
      for (let i = 0; i < cities_list.length; i++) {
        const element = cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
          };
          this.treeDataCity[0].children.push(obj)
        }
      }
      this.treeDataCity[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataCity[0].children
      );
      if (this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'GM' || this.logged_in_user.team == 'ExEd' || this.logged_in_user.team == 'SR') {
        this.initSelectedCity = this.treeDataCity
        this.selectedCities = [this.logged_in_user.city]
      }
    },
    getCityClassificationSpocs(spocs_data) {
      this.treeDataCity = [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ]
      let cities_list = [...new Set(spocs_data.map((item) => item.city))];
      console.log("cities list order", cities_list);
      let city_options = ["Ahmedabad","Bangalore","Chennai","Delhi","Ernakulam","Hyderabad","Kolkata","Mumbai","Pune",]
      let duplicates2 = []
      this.treeDataCity[0].children = [];
      for (let i = 0; i < cities_list.length; i++) {
        const element = cities_list[i];
        if (element != null) {
          var obj = {
            id: element,
            label: element,
            children: [],
          };
          if(city_options.includes(element)){
            if (this.logged_in_user.team == 'CM') {
            if (this.logged_in_user.city == element) {
              this.treeDataCity[0].children.push(obj)
            }
            } else if (this.logged_in_user.team == 'SR') {
              if (this.logged_in_user.city == element) {
                this.treeDataCity[0].children.push(obj)
              }
            } else if (this.logged_in_user.team == 'ExEd') {
              if (this.logged_in_user.city == element) {
                this.treeDataCity[0].children.push(obj)
              }
            } else if (this.logged_in_user.team == 'GM') {
              this.gm_spoc_ids.forEach(element1 => {
                if (element1.city == element && !duplicates2.includes(element)) {
                  this.treeDataCity[0].children.push(obj)
                  duplicates2.push(element)
                }
              });
            } else { this.treeDataCity[0].children.push(obj) }
        }
        }
      }
      this.treeDataCity[0].children = this.sortNestedArrayAlphabetically(
        this.treeDataCity[0].children
      );
      if (this.logged_in_user.team == 'CM' || this.logged_in_user.team == 'GM' || this.logged_in_user.team == 'ExEd' || this.logged_in_user.team == 'SR') {
        this.initSelectedCity = this.treeDataCity
        this.selectedCities = [this.logged_in_user.city]
      }
      spocs_data.forEach((element) => {
        this.treeDataCity[0].children.forEach((city) => {
          if (element.city == city.label) {
            if (
              !city.children.find(
                (item) => item.label === element.city_classification
              )
            ) {
              //to remove city classification duplicates

              if(this.logged_in_user_id == 132 ||this.logged_in_user_id == 937 || this.logged_in_user_id == 39){
                if (element.city_classification != null) {
                var obj = {
                  id: element.city + "_" + element.city_classification, // x is in more than one city
                  label: element.city_classification,
                };
                if (this.logged_in_user.team == 'CM') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(obj);
                  }
                } else if (this.logged_in_user.team == 'ExEd') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(obj);
                  }
                } else if (this.logged_in_user.team == 'SR') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(obj);
                  }
                } else if (this.logged_in_user.team == 'GM') {
                  this.gm_spoc_ids.forEach(element1 => {
                    if (element1.city_classification == element.city_classification && !duplicates2.includes(element.city_classification)) {
                      city.children.push(obj);
                      duplicates2.push(element.city_classification)
                    }
                  });
                } else { city.children.push(obj); }
              }
              }
              else{
              if (element.city_classification != null && element.city_classification != 'X' ) {
                var object = {
                  id: element.city + "_" + element.city_classification, // x is in more than one city
                  label: element.city_classification,
                };
                if (this.logged_in_user.team == 'CM') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(object);
                  }
                } else if (this.logged_in_user.team == 'ExEd') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(object);
                  }
                } else if (this.logged_in_user.team == 'SR') {
                  if (this.logged_in_user.cc == element.city_classification) {
                    city.children.push(object);
                  }
                } else if (this.logged_in_user.team == 'GM') {
                  this.gm_spoc_ids.forEach(element1 => {
                    if (element1.city_classification == element.city_classification && !duplicates2.includes(element.city_classification)) {
                      city.children.push(object);
                      duplicates2.push(element.city_classification)
                    }
                  });
                } else { city.children.push(object); }
              }
            }
            }
          }
        });
      });
      console.log("spocs_data 21324", spocs_data);
      let duplicates = []
      if(localStorage.getItem("spoc_id") != 794 && !this.admin_access_spocs.includes(this.spoc_email_id) && localStorage.getItem("team") != 'ExEd' &&  localStorage.getItem("sub_team") != 'MAcc'){
      this.selected_spocs = []
      this.initSelectedSpocs = []
      this.gm_spoc_ids.forEach(element => {
        if (!duplicates.includes(element.id) && element.active) {
          this.initSelectedSpocs.push({ id: element.id, label: element.full_name })
          this.selected_spocs.push(element.full_name)
          duplicates.push(element.id)
        }
      })
    }
      console.log("this.initSelectedSpocs 1", this.initSelectedSpocs);

    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";

.my_date_picker>div input {
  width: 95%;
}

.my_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.lead-option-side {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.no-padding {
  padding: 0px;
}

.eligibility_count {
  background: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1%;
  align-items: center;
}

.eligibility_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.none_padding {
  padding: 0px;
}

.a-iconns>.material-icons {
  margin-left: auto;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.lead>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.lead-tree-drop#citys>.vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid rgba(46, 13, 146, 0.5);
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.lead-tree-drop#spoc>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #6ac8e6;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.lead-tree-drop#course>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #f5b3b3;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.lead-tree-drop#team>.vs-con-dropdown {
  background: #caecc5;
  border: 1px solid #a3d79b;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.lead-tree-drop#focus>.vs-con-dropdown {
  background: #ffcfb4;
  border: 1px solid #ec9d71;
  border-radius: 10px;
  width: 90%;
}

.lead-tree-drop#level_drop>.vs-con-dropdown {
  background: #ffdced;
  border: 1px solid #ed9dc4;
  border-radius: 10px;
  width: 90%;
}

.lead-tree-drop>.vs-con-dropdown {
  width: 90%;
  min-height: 32px;
  border-radius: 10px;
  // padding: 5.8px;
  padding-inline: 5px;
  // padding-top:3px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.alignment {
  text-align: initial;
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.lead_search_criteria {
  width: 100%;
  background: #ffffff;
  min-height: 165px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.lead_dropdown .vs__dropdown-toggle {
  height: 45px !important;
}

.lead_dropdown .vs__dropdown-toggle .vs__selected-options span {
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
}

.lead>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.lead-option-side {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.search-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  height: 564.69px;
  margin-bottom: 30px;
}

// old css
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}

.vs-dropdown--menu {
  width: 300px;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black;
  height: 35px;
}

#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar {
  overflow-y: scroll;
}

.vs--searchable .vs__dropdown-toggle {
  height: 38px;
}

.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}

.gap {
  margin-bottom: 4%;
}

.inicolor {
  color: gray;
}

.cardtext {
  color: gray;
  font-size: 20px;
}

.tree3 {
  // float: left;
  // width: 33%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}

.vs-dropdown--menu {
  width: 300px;
  // height: 500px;
  // overflow: hidden;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gap {
  margin-right: 1%;
}

.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.vl {
  border-left: 1px solid black;
  height: 35px;
}

.search-container {
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  width: 100%;
  min-height: 450.69px;
}

.eligibility_count {
  background: #0044ba;
  border-radius: 15px 0px;
  width: 233.62px;
  height: 37.8px;
  display: flex;
  justify-content: space-between;
  padding-inline: 1%;
  align-items: center;
}

.eligibility_text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.lead-options_filter {
  min-height: 402.41px;
  margin: 2px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.lead-options_filter .vx-card__collapsible-content .vx-card__body {
  padding: 5px 20px !important;
}

.filter-heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.none_padding {
  padding: 0px;
}

.lead-tree-drop#citys>.vs-con-dropdown {
  background: #eae5fa;
  border: 1px solid rgba(46, 13, 146, 0.5);
  width: 90%;
  border-radius: 10px;
  margin-inline: 2%;
}

.lead-tree-drop#cc>.vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.lead-tree-drop#spoc-dropdown>.vs-con-dropdown {
  background: rgba(188, 235, 250, 0.69);
  border: 1px solid #1fabd8;
  border-radius: 10px;
  width: 100%;
  margin-inline: 2%;
}

.lead-tree-drop#sc>.vs-con-dropdown {
  background: #b4edff;
  border: 1px solid #13a0cd;
  border-radius: 10px;
  width: 90%;
  margin-inline: 2%;
}

.lead-tree-drop#course>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  // width: 100%;
  // margin-inline: 2%;
}

.lead-tree-drop#level>.vs-con-dropdown {
  background: rgba(255, 194, 194, 0.69);
  border: 1px solid #ec6868;
  border-radius: 10px;
  width: 100%;
  line-height: 35px;
}

.lead-tree-drop#status>.vs-con-dropdown {
  background: #F5E1CE;
  border: 1px solid #F1A964;
  border-radius: 10px;
  width: 100%;
}

.lead-tree-drop#type>.vs-con-dropdown {
  background: #f1ad78;
  border: 1px solid #F38630;
  border-radius: 10px;
  width: 100%;
}

.lead-tree-drop#level_drop>.vs-con-dropdown {
  background: #fae1fe;
  border: 1px solid #de56f4;
  border-radius: 10px;
  width: 100%;
}

.lead-tree-drop#macc_level_drop>.vs-con-dropdown {
  background: #edebf3;
  border: 1px solid #2e0d92;
  border-radius: 10px;
  width: 100%;
}

.lead-tree-drop>.vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  // padding: 5.8px;
  padding-inline: 5px;
  // padding-top:3px;
  color: #000000;
  display: flex !important;
  align-items: center;
}

.alignment {
  text-align: initial;
}

.ele-inside-text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  padding-inline-start: 10px;
}

.ele-inside-text>i {
  color: rgba(0, 0, 0, 0.4);
}

.a-iconns>.material-icons {
  margin-left: auto;
}

.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

i {
  font-size: 18px;
}

.a-iconns {
  color: #000;
  font-weight: 500;
  font-size: 14px;
  width: 99%;
  display: flex;
  justify-content: flex-end !important;
}

.lead_dropdown .vs__dropdown-toggle {
  height: 45px !important;
}

.lead_dropdown .vs__dropdown-toggle .vs__selected-options span {
  font-weight: 500;
  font-size: 14px;
  font-family: "Montserrat";
  font-style: normal;
  color: #000000;
}

.lead>.reportrange-text {
  background: #ffffff;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgb(0 0 0 / 16%);
  min-height: 44.47px;
  border-radius: 10px;
}

.date_filter_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}

.lead_date_picker>div input {
  background: #ffffff;
  -webkit-padding-start: 9%;
  display: block;
  border-radius: 5px;
  min-height: 44.47px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  width: 100%;
  border: 1px solid #0044ba;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.16);
  color: #000000 !important;
  border-radius: 10px;
}

.lead_search_criteria {
  width: 100%;
  background: #ffffff;
  min-height: 165px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 15px;
}

.Criteria_heading {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
}

.lead-option-side {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.criteria_chip {
  border-radius: 5px;
}

.criteria_chip .vs-chip--close {
  background: transparent !important;
  color: #000000;
}

.criteria_chip .vs-chip--text {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  text-align: center;
  color: #000000;
}

.lead-input-filter {
  width: 100% !important;
}

.lead-input-filter.vs-con-input .input-span-placeholder {
  font-size: 14px;
  margin-left: 1%;
}

.lead-input-filter>.vs-con-input>.vs-inputx {
  height: 40px;
  border: 1px solid #0044ba !important;
  border-radius: 15px;
}

.lead-identity-input-filter {
  height: 40px;
  border: 1px solid #0044ba;
  border-radius: 6px;
}

.no-padding {
  padding: 0px;
}

.no-padd {
  padding: 0% !important;
}

.labell {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
}
</style>
