<template>
  <div>
    <vx-card class="tabulator_card">
      <vs-row class="mb-3">
        <vs-col vs-w="3" vs-type="flex" vs-justify="flex-start"
        class="hide-tree-drop none_padding"
        id="hide"
        >
          <vs-dropdown vs-custom-content vs-trigger-click>
                <a
                  class="a-icon a-iconns ele-inside-text"
                  href.prevent
                  @click="showTreeBox"
                  style="text-align: left;"
                >
                  Hide Columns
                  <i class="material-icons">expand_more</i>
                </a>
                <vs-dropdown-menu class="dropdown-login customDropDown">
                  <treeselect
            v-model="selected_column"
            :multiple="true"
            valueFormat="object"
            :options="treeDataHide"
          />
                </vs-dropdown-menu>
              </vs-dropdown>

        </vs-col>
        <vs-col
          vs-w="3"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
        >
          <vs-button color="#0044BA" size="small" @click="HideColumn">
            Hide - Unhide Columns
          </vs-button>
        </vs-col>
      </vs-row>
      <vs-row style="margin-top: 2%">
        <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2" class="mb-6">
          <b>Search Criteria:</b>
        </vs-col>
        <vs-col
          style="overflow-y: scroll"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="center"
          vs-w="9"
          class="mb-6"
        >
          <div v-for="(chip, index) in searchCriteria" :key="index">
            <vs-chip
              closable
              @click="removechip(chip.id)"
              color="#0044BA"
            >
              <b>{{ chip.label}}</b>
            </vs-chip>
          </div>
        </vs-col>
      </vs-row>
      <div ref="table2" id="example-table-theme"></div>
    </vx-card>
  </div>
</template>

<script>
import { TabulatorFull as Tabulator } from "tabulator-tables";
import constants from "../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import EventBus from "../components/eventbus";
import axios from "axios";
// import moment from "moment";
export default {
   components: {
    Treeselect,
  },
  props: ["all_leads"],
  mounted() {
    // this.getLeadsTable();
    EventBus.$on("selected-team", (data) => {
      this.selected_team = data;
      console.log("selectedteam--", this.selected_team)
    this.getLeadsTable();

    })
  },
  data() {
    return {
      searchCriteria: [],
      column_name: "",
      levels_list: ['M7', 'M8', 'M9', 'M10', 'M7-', 'M8-', 'M9-', 'M7+', 'M7X', 'M7#', 'M7JJ', 'M8+', 'M7D1', 'M7D2', 'M7-IR', 'M7-INT'],
      visitor_details: [],
      selected_column: [],
      treeDataHide:[
        {
          id:"company",
          label:"Company"
        },
        {
          id:"experience_int",
          label:"Experience"
        },
        {
          id:"education_tags",
          label:"Education"
        },
        {
          id:"comments",
          label:"Comments"
        },
        {
          id:"level",
          label:"CM Level"
        },
        {
          id:"iiml_level",
          label:"USP Level"
        },
        {
          id:"enrollment_date",
          label:"Enrollment Date"
        },
        {
          id:"courses",
          label:"Course"
        },
        {
          id:"spoc_name",
          label:"CM SPOC"
        },
        {
          id:"sr_name",
          label:"SR SPOC"
        },
        {
          id:"assigned_iiml_name",
          label:"USP SPOC"
        },
        {
          id:"fam_lam_data.first_source",
          label:"FAM"
        },
        {
          id:"fam_lam_data.last_source",
          label:"LAM"
        },
        {
          id:"mhp_date",
          label:"MHP Date"
        },
        {
          id:"last_call",
          label:"CM Last Call"
        },
        {
          id:"next_call",
          label:"CM Next Call"
        },
        {
          id:"sr_last_call",
          label:"SR Last Call"
        },
        {
          id:"sr_next_call",
          label:"SR Next Call"
        },
        {
          id:"macc_last_call",
          label:"USP Last Call"
        },
        {
          id:"macc_next_call",
          label:"USP Next Call"
        },
      ],
      selected_team: [],
    };
  },
  watch: {
    selected_column(val){
      this.searchCriteria = [];
      if (val == 'All') {
        this.searchCriteria.push('All')
      }else if (val.length != 0 && val != 'All') {
        for (let k = 0; k < val.length; k++) {
          this.searchCriteria.push(val[k])
        }
      }
    },
    all_leads(val) {
      this.all_leads = val;
      console.log("all_leads", val);
      this.getLeadsTable();
      // for (let i = 0; i < this.visitor_details.length; i++) {
      //   const element = this.visitor_details[i];
      //   const visit_type = {
      //     visit: "Visit",
      //     call: "Call",
      //     email: "Email",
      //     whatsapp: "Whatsapp",
      //   };
      //   element.type = visit_type[element.type];
      //   if (element.first_visit_date !== null) {
      //     element.first_visit_date = moment(element.first_visit_date).format(
      //       "DD-MMM-YYYY"
      //     );
      //   }
      //   if (element.enrollment_date !== null) {
      //     element.enrollment_date = moment
      //       .unix(element.enrollment_date)
      //       .format("DD-MMM-YYYY");
      //   }

      //   if (element.last_visit_date !== null) {
      //     element.last_visit_date = moment(element.last_visit_date).format(
      //       "DD-MMM-YYYY"
      //     );
      //   }
      //   if (element.created_at !== null) {
      //     element.created_at = moment(element.created_at).format("DD-MMM-YYYY");
      //   }
      //   if (element.last_call !== null) {
      //     element.last_call = moment
      //       .unix(element.last_call)
      //       .format("DD-MMM-YYYY");
      //   }
      //   if (element.next_call !== null) {
      //     element.next_call = moment
      //       .unix(element.next_call)
      //       .format("DD-MMM-YYYY");
      //   }
      //   if (element.mhp_date !== null) {
      //     element.mhp_date = moment
      //       .unix(element.mhp_date)
      //       .format("DD-MMM-YYYY");
      //   }
      //   Object.assign(element, { info: "About" });
      // }
    },
  },
  methods: {
    showTreeBox() {
      return this.treeDataHide;
    },
    removechip(chip){
      if (this.selected_column.indexOf(chip) === -1) {
        this.selected_column = [];
        this.treeDataHide[0].children.forEach((child) => {
          if (child.label !== chip) {
            this.selected_column.push(child.label);
          }
        });
      } else {
        this.selected_column.splice(
          this.selected_column.indexOf(chip),
          1
        );
      }
    },
    // getChipData(chip){
    //   var chip_val = chip
    //   if (chip_val.includes('.')) {
    //     chip_val = chip_val.substr(chip_val.indexOf('.') + 1);
    //   }
    //   if (chip_val.includes('_')) {
    //     chip_val=chip_val.replace(/_/g," ");
    //   }
    //   return chip_val
    // },

    getLeadsTable() {
      let column_headers = [
        {
          title: "Can-ID",
          field: "identity",
          frozen: true,
          sorter: "alphanum",
        },
        {
          title: "Candidate Name",
          field: "person_name",
          frozen: true,
        },
        {
          title: "Company",
          field: "company",
        },
        {
          title: "Experience",
          field: "experience_int",
        },
        {
          title: "Education",
          field: "education_tags",
        },
        {
          title: "USP Level",
          field: "iiml_level",
        },
        {
          title: "CM Level",
          field: "level",
          sorter: "alphanum",
          // cellClick: this.getColor()
        },
          {
          title: "Enrollment Date",
          field: "enrollment_date",
          headerSort: false,
        },
        {
          title: "Course",
          field: "courses",
        },
        {
          title: "CM SPOC",
          field: "spoc_name",
        },
        {
          title: "USP SPOC",
          field: "assigned_iiml_name",
        },
        {
          title: "SR SPOC",
          field: "sr_name",
        },
        {
          title: "FAM",
          field: "fam_lam_data.first_source",
          headerSort: false,
        },
        {
          title: "LAM",
          field: "fam_lam_data.last_source",
          headerSort: false,
        },
        {
          title: "MHP Date",
          field: "mhp_date",
        },
        {
          title: "Comments",
          field: "comments",
          headerSort: false,
        },
        {
        title: "About",
        field: "info",
        frozen: true,
        formatter:"image",
        hozAlign:"center",
        headerSort: false,
        formatterParams:{
          height:"25px",
          width:"35px",
        },
        cssClass: "about-cell",
        cellClick: this.getId,
      },
      ];

      if(this.selected_team[0] == 'CM'){
        let cm_last_call =   {
          title: "CM Last Call",
          field: "last_call",
          headerSort: false,
        };
        let cm_next_call =   {
          title: "CM Next Call",
          field: "next_call",
          headerSort: false,
        };
        column_headers.push(cm_last_call);
        column_headers.push(cm_next_call);

      }
      if(this.selected_team[0] == 'MAcc'){
        let macc_last_call =   {
          title: "USP Last Call",
          field: "iiml_last_call",
          headerSort: false,
        };
        let macc_next_call =   {
          title: "USP Next Call",
          field: "iiml_next_call",
          headerSort: false,
        };
        column_headers.push(macc_last_call);
        column_headers.push(macc_next_call);

      }
      if(this.selected_team[0] == 'SR'){
        let sr_last_call =   {
          title: "SR Last Call",
          field: "sr_last_call",
          headerSort: false,
        };
        let sr_next_call =   {
          title: "SR Next Call",
          field: "sr_next_call",
          headerSort: false,
        };
        column_headers.push(sr_last_call);
        column_headers.push(sr_next_call);

      }

      // column_headers.unshift({
      //   title: "About",
      //   field: "info",
      //   frozen: true,
      //   formatter:"image",
      //   hozAlign:"center",
      //   headerSort: false,
      //   formatterParams:{
      //     height:"25px",
      //     width:"35px",
      //   },
      //   cssClass: "about-cell",
      //   cellClick: this.getId,
      // },),
      column_headers.push({title:"Enrollment",
        field:"erollment_form",
        formatter:"image",
        hozAlign:"center",
        headerSort: false,
        formatterParams:{
          height:"20px",
          width:"20px",
        },
        cellClick: this.openEnrollmentForm,
        },),
        column_headers.push(          {
        title: "IIML Enrollment Form",
        field: "iiml_erollment_form",
        formatter:"image",
        headerSort: false,
        hozAlign:"center",
        formatterParams:{
          height:"20px",
          width:"20px",
        },
        cellClick: this.openEnrollmentForm,
      },),
      this.tabulator = new Tabulator(this.$refs.table2, {
        maxHeight: "80vh",
        data: this.all_leads,
        rowFormatter: (function(row) {
          var evenColor = "#F5F6FA";
          var oddColor = "#FFF";
          row.getElement().style.backgroundColor = row._row.position % 2 === 0 ? evenColor : oddColor;
        }),
        columns: column_headers,
      });

      console.log("selected team", this.team);

    },
    getId(e, cell) {
      console.log("About popup", e, cell._cell.row.data.id);
        this.openBigPopup(cell._cell.row.data.id);
        // this.openBigPopup(cell._cell.row.data.mwb_id);
    },
    convertToTimeStamp(e, cell){
      console.log("convertToTimeStamp",e, cell);
    },
    HideColumn(){
      this.treeDataHide.forEach((treeData) => {
        this.tabulator.showColumn(treeData.id)
      });
      if(this.selected_column.length > 0){
        this.selected_column.forEach((selected_item)=>{
          this.treeDataHide.forEach((item) =>{
              if(item.id == selected_item.id){
                this.tabulator.hideColumn(item.id)
              }
            })
          })
      }
    },
    openEnrollmentForm(e, cell) {
      this.column_name = "";
      this.column_name = cell.getColumn().getDefinition().title;
      console.log("open popup",e, cell._cell.row.data, cell.getColumn().getDefinition().title);
      let lead_data = cell._cell.row.data;
      if (!this.levels_list.includes(lead_data.level) && localStorage.getItem('team') != 'ExEd') {
        this.showCustomNotification(
          "Not Valid",
          "The lead's level must be M7 or above M7 to start the enrollment process",
          "danger"
        );
        // this.$vs.loading.close();
        return;
      }

      this.$vs.loading();

      let getParams = {
        mwb_id: lead_data.id,
      };
      let url = "https://crm.milesforce.com/api/getCandidateDetails";
      axios
        .get(url, {
          params: getParams,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          if (response.data.status !== "success") {
            this.handleNotification(response);
          } else {
            this.uspReEnrollmentStatus(response.data.data)
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.handleError(error);
          this.$vs.loading.close();
        });
    },
    validateLead(leadData) {
      // console.log(leadData);
      if (leadData.emails.length === 0 || leadData.emails.length === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires atleast one email to start the enrollment process",
          "danger"
        );
        return;
      } else if (leadData.mwb.company === "" || leadData.mwb.company === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the company field to be filled to start the enrollment process",
          "danger"
        );
        return;
      } else if (leadData.mwb.designation === "" || leadData.mwb.designation === null) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the designation field to be filled to start the enrollment process",
          "danger"
        );
        return;
      } else if (
        leadData.mwb.experience_int === "" ||
        leadData.mwb.experience_int === null
      ) {
        this.showCustomNotification(
          "Not Valid",
          "Requires the experience field to be filled to start the enrollment process",
          "danger"
        );
        return;
      }
      // else if (
      //   leadData.mwb.education_tags === "" ||
      //   leadData.mwb.education_tags === null
      // ) {
      //   this.showCustomNotification(
      //     "Not Valid",
      //     "Requires atleast one education tag to start the enrollment process",
      //     "danger"
      //   );
      //   return;
      // }
      else if (!this.primaryExists(leadData.emails)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary email is required to start the enrollment process",
          "danger"
        );
        return;
      } else if (!this.primaryExists(leadData.mobiles)) {
        this.showCustomNotification(
          "Not Valid",
          "Primary mobile number is required to start the enrollment process",
          "danger"
        );
        return;
      }
      let mobileString = this.fetchMobileNumbers(leadData);
      let emailString = this.fetchEmailIds(leadData);
      leadData.mwb.emailString = emailString;
      leadData.mwb.mobileString = mobileString;
      // console.log(emailString);
      // console.log(mobileString);
      // leadData.mwb.enrollments = leadData.enrollments;
      leadData.mwb.payloadEmails = leadData.emails;
      if(this.column_name == 'Enrollment'){
        EventBus.$emit("open-enrollment-popup", leadData.mwb);
      }
      if(this.column_name == 'IIML Enrollment Form'){
        EventBus.$emit("open-IIMLEnrollmentPopup", leadData.mwb);
      }
      // console.log("Next validation");
    },
    uspReEnrollmentStatus(payload) {
      let add_usp_course  = false
      let url = `${constants.SERVER_API}getUSPReEnrollmentStatus?mwb_id=${payload.mwb.id}`;
      axios.get(url, {headers: { Authorization: `Bearer ${localStorage.userAccessToken}` }})
        .then((response) => {
          if (response.data.usp_re_enrollment_status.length != 0 && response.data.usp_re_enrollment_status[0].approval_status =="approved") {
            add_usp_course = true
          }
          EventBus.$emit("add-usp-course", add_usp_course);
          this.validateLead(payload);

        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    primaryExists(arrayForValidation) {
      let bool = false;
      arrayForValidation.forEach((item) => {
        if (item.pri_mary === 1) {
          bool = true;
        }
      });
      return bool;
    },

    async fetchRequiredData(payload) {
      let mobileString = await this.fetchMobileNumbers(payload);
      let emailString = await this.fetchEmailIds(payload);
      if (emailString === "") {
        this.$vs.notify({
          title: "Not Valid",
          text: "Requires atleast one email to start the enrollment process",
          color: "danger",
        });
      } else {
        payload.emailString = emailString;
        payload.mobileString = mobileString;
        EventBus.$emit("open-enrollment-popup", payload);
      }
      this.$vs.loading.close();
    },

    fetchMobileNumbers(payload) {
      let concatString = [];
      if (payload.mobiles.length === 0) {
        let empty = "";
        return empty;
      } else {
        payload.mobiles.forEach((mobile) => {
          if (mobile.pri_mary === 1) {
            concatString.push(mobile.last_ten_digits);
          }
        });
        concatString = concatString.join();
        return concatString;
      }
    },

    fetchEmailIds(payload) {
      let concatString = [];
      if (payload.emails.length === 0) {
        let empty = "";
        return empty;
      } else {
        payload.emails.forEach((email) => {
          if (email.pri_mary === 1) {
            concatString.push(email.email);
          }
        });
        concatString = concatString.join();
        return concatString;
      }
    },
  },
};
</script>

<style lang="scss">
.tabulator_card {
  border-radius: 15px;
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
}

.tabulator .tabulator-row:nth-child(odd) {
  background-color: #f2f2f2;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}

.tabulator .tabulator-row:nth-child(even) {
  background-color: #e4e1e1;
  color: rgb(0, 0, 0) !important;
  /* padding-block: 5px; */
}
.tabulator-calcs-bottom .tabulator-cell {
  background-color: rgb(0, 0, 0);
  color: #f2f2f2 !important;
}
.hide-tree-drop#hide > .vs-con-dropdown {
 background: #edebf3;
  border: 1px solid #2e0d92;
  border-radius: 10px;
  width: 100%;
}
.hide-tree-drop > .vs-con-dropdown {
  width: 100%;
  min-height: 32px;
  border-radius: 10px;
  padding-inline: 5px;
  color: #000000;
  display: flex !important;
  align-items: center;
}
.about-cell img{
  padding-inline-start: 10px;
}
</style>
